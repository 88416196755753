export const skillsData = [
    {
        label: "FRONT END",
        data: [
            {
                skillName: "HTML",
                percentage: 100,
                visible: true
            },
            {
                skillName: "CSS / SASS",
                percentage: 80,
                visible: true
            },
            {
                skillName: "JAVASCRIPT",
                percentage: 70,
                visible: true
            },
            {
                skillName: "REACT JS",
                percentage: 50,
                visible: true
            },
            {
                skillName: "JSP",
                percentage: 60,
                visible: true
            }
        ],
        visible: true
    
    },
    {
        label: "BACK END",
        data: [
            {
                skillName: "JAVA",
                percentage: 60,
                visible: true
            },
            {
                skillName: "PHP",
                percentage: 60,
                visible: true
            },
            {
                skillName: "PYTHON",
                percentage: 20,
                visible: true
            }
        ],
        visible: true
    
    },
    {
        label: "DATABASES",
        data: [
            {
                skillName: "MYSQL",
                percentage: 60,
                visible: true
            },
            {
                skillName: "REDIS",
                percentage: 10,
                visible: true
            }, 
            {
                skillName: "FIREBASE",
                percentage: 30,
                visible: true
            }
        ],
        visible: true
    
    },
    {
        label: "SALESFORCE",
        data: [
            {
                skillName: "USER CONFIG",
                percentage: 90,
                visible: true
            },
            {
                skillName: "DECLARTIVE AUTOMATION",
                percentage: 90,
                visible: true
            },
            {
                skillName: "APEX",
                percentage: 70,
                visible: true
            },
            {
                skillName: "VISUALFORCE",
                percentage: 80,
                visible: true
            },
            {
                skillName: "LIGHTNING WEB COMPONENTS",
                percentage: 70,
                visible: true
            },
            {
                skillName: "SECURITY",
                percentage: 80,
                visible: true
            }
        ],
        visible: true
    
    }
]