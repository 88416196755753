const getAge = () => {
    let today = new Date();
    const birthDate = new Date('1987/10/30');
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export const personalData = [
    {
        label: 'Name',
        value: 'Daniel Boggs',
        visible: true
    },
    {
        label: 'Age',
        value: getAge(),
        visible: true
    },
    {
        label: 'Location',
        value: 'Los Angeles, CA',
        visible: true
    },
    {
        label: 'Email',
        value: 'Available Upon Request',
        visible: true
    },
    {
        label: 'Phone',
        value: 'Available Upon Request',
        visible: true
    },
]