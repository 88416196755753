import React from 'react';
import './styles.scss'
import PageHeaderContent from '../../components/pageHeaderContent';
import { GiSkills } from 'react-icons/gi'
import { skillsData } from './utils';
import { Line } from 'rc-progress'
import { Animate } from 'react-simple-animate';

const Skills = () => {
    return (
        <section id='skills' className='skills'>
            <PageHeaderContent 
                headerText='My Skills'
                icon={<GiSkills />}
            />

            <div className="skills__content-wrapper">
                {
                    skillsData.filter((item) => item.visible).map((item, key) => (
                        <div key={key} className="skills__content-wrapper__inner-content">
                            <Animate
                                play
                                duration={1}
                                delay={0}
                                start= {{
                                    transform: 'translateY(500px)'
                                }}
                                end={{
                                    transform: 'translateY(0px)'
                                }}
                            >
                                <h3 className='skills__content-wrapper__inner-content__category-text'>{item.label}</h3>
                                <div>
                                    {
                                        item.data.filter((dataItem) => dataItem.visible).map((dataItem, index) => (
                                                <div key={index} className='progressbar-wrapper'>
                                                    <p>{dataItem.skillName}</p>
                                                    <Line 
                                                        percent={dataItem.percentage}
                                                        strokeWidth='2'
                                                        strokeColor='var(--font-color)'
                                                        strokeLinecap='square'
                                                        trailWidth='2'
                                                    />
                                                </div>
                                        ))
                                    }
                                </div>
                            </Animate>
                        </div>
                    ))
                }
            </div>
        </section>
    );
};

export default Skills;