import PageHeaderContent from '../../components/pageHeaderContent';
import { FaLaptopCode } from 'react-icons/fa'

import './styles.scss'

const Portfolio = () => {
    return (
        <section id='portfolio' className='portfolio'>
            <div className='portfolio-content'>
                <PageHeaderContent 
                    headerText='My Portfolio'
                    icon={<FaLaptopCode />}
                />
            </div>
        </section>
    )
}

export default Portfolio;