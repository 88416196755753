export const data = {
    experience: [
        {
            title: "Salesforce Engineer",
            subTitle: "Kayne Anderson Capital - Los Angeles, CA",
            type: "Full TIme",
            dates: "Sep 2022 - Jun 2023",
            description:
            "• Work with business users and analysts to understand project requirements and propose best practice solutions to be implemented in a timely manner.\n" +
            "• Hands on development and maintenance of various components of Salesforce implementation - core Visualforce, Lightning Web Components, and Apex.\n" +
            "• Declarative Admin / Development functions on the Salesforce & supporting platforms.",
            visible: true
        },
        {
            title: "Software Engineer",
            subTitle: "Sunbasket - Los Angeles, CA",
            type: "Full TIme",
            dates: "Oct 2021 - Jul 2022",
            description:
            "• Work with stakeholders to determine technical implications of specific requests and write out technical specifications based off of the product spec.\n" +
            "• Deliver quality frontend/backend solutions in a timely manner especially with regarding the planned developmental timeline.\n" +
            "• Create tests that will cover all use-case.",
            visible: true
        },
        {
            title: "Associate Software Engineer",
            subTitle: "Sunbasket - Los Angeles, CA",
            type: "Full TIme",
            dates: "Apr 2021 - Oct 2021",
            description:
            "• Assist in the development of the website and hot fixes from assigned tickets\n" +
            "• Develop a demonstrated understanding of the core frontend (HTML/CSS/JavaScript) and backend (Java/PHP) programming languages.\n"+
            "• Demonstrate the ability to apply database changes through SQL statements and migrations.",
            visible: true
        },
        {
            title: "Salesforce / Systems Developer",
            subTitle: "Sunbasket - San Jose, CA",
            type: "Full TIme",
            dates: "Apr 2020 - Apr 2021 ",
            description:
            "• Designing, coding, and implementing Salesforce & cross-platform applications.\n" +
            "• Oversee assigned CS systems and tools to enhance customer service interface and services.\n" +
            "• Provide real-time troubleshooting assistance to CS team members and partner with vendors to ensure we are receiving the appropriate external customer service.",
            visible: true
        },
        {
            title: "Salesforce / Systems Admin",
            subTitle: "Sunbasket - San Jose, CA",
            type: "Full TIme",
            dates: "Jan 2019 - Apr 2020 ",
            description:
            "• Oversee assigned CS systems and tools to enhance customer service interface and services. As well as, develop internal solutions for automation and case deflection.\n" +
            "• Provide analytical support, creation of metrics and ensure that systems reflect such information.\n" +
            "• Provide real-time troubleshooting assistance to CS team members and partner with vendors to ensure we are receiving the appropriate external customer service.",
            visible: true
        }
    ],

    education: [
        {
            title: "Zero To Mastery Academy",
            subTitle: "Online",
            type: "Full Time",
            dates: "2022 - 2022 ",
            description:
            "React JS Developer Certification",
            visible: true
        },
        {
            title: "The Art Institute of California - San Francisco",
            subTitle: "San Francisco, CA",
            type: "Full Time",
            dates: "2008 - 2010 ",
            description:
            "Digital Filmmaking",
            visible: true
        },
        {
            title: "Milpitas High School",
            subTitle: "Milpitas, CA",
            type: "Full TIme",
            dates: "2002 - 2006 ",
            description:
            "General Education",
            visible: true
        }
    ],

    certifications: [
        {
            title: "Salesforce Certified Administrator",
            subTitle: "Salesforce",
            dates: "Mar 2019 - Present ",
            description:
            "",
            visible: true
        },
        {
            title: "Salesforce Certified Platform App Builder",
            subTitle: "Salesforce",
            dates: "Apr 2019 - Jan 2022 ",
            description:
            "",
            visible: true
        }
    ]
}
