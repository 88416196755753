import React, { useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom'
//import  TypeMe from 'react-typeme'
import { Animate } from 'react-simple-animate'
import { FaLinkedin, FaGithubSquare, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa'
// eslint-disable-next-line
import { RiShareBoxLine } from 'react-icons/ri'
import Resume from '../../files/Daniel_Boggs_Resume.pdf'
import Modal from "react-modal";
import { RiCloseFill } from 'react-icons/ri';
import { TypeAnimation } from 'react-type-animation';

Modal.setAppElement('#root');

/*
                    <TypeMe
                        strings={[
                            'Heello, I\'m Daniel Boggs.' +
                            '\nFull Stack Developer.'
                        ]}
                        typeSpeed={40}
                        backSpeed={50}
                        loop={false}
                        showCursor={true}
                    />*/

const Home = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <section id='home' className='home'>
            <div className="home__text-wrapper">
                { /*<h1 className='type-header'>
                    Hello I'm Daniel Boggs,<br />
                    a full stack developer
    </h1> */}
                <h1 className='type-header'>
                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed once, initially
                        'Hello. I\'m Daniel Boggs,',
                        2000
                    ]}
                    speed={10}
                    style={{  }}
                    repeat={0}
                    cursor={false}
                    preRenderFirstString={true}
                />
                <br />
                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed once, initially
                        'a full stack developer',
                        1000,
                        'a salesforce developer',
                        1000,
                        'a systems admin',
                        1000,
                        'a telephony designer',
                        1000
                    ]}
                    speed={45}
                    style={{  }}
                    repeat={Infinity}
                />
                </h1>
            </div>
            <Animate
                play={true}
                duration={1.5}
                delay={0}
                start={{
                    transform: 'translateY(500px)'
                }}
                end={{
                    transform: 'translateX(0px)'
                }}
                >
                <div className="contact-me">
                    <div className="contact-me__buttons-wrapper">
                        <Link to='/contact'>Hello, it's me...</Link>
                        <a href={Resume} target="_blank" rel='noreferrer'>Download resume <sup><RiShareBoxLine /></sup></a>
                        {/*<button onClick={toggleModal}>Download resume</button>*/}
                    </div>
                    <div className="contact-me__socials-wrapper">
                        <a href='https://www.linkedin.com/in/boggsdaniel/' target='_blank' rel='noreferrer'>
                            <FaLinkedin className='icon-linkedin' />
                        </a>
                        <a href='https://github.com/daniel-boggs/' target='_blank' rel='noreferrer'>
                            <FaGithubSquare className='icon-github' />
                        </a>
                        <a href='https://www.facebook.com/boggs.e.daniel/' target='_blank' rel='noreferrer'>
                            <FaFacebookSquare className='icon-facebook' />
                        </a>
                        {/*<a href='https://www.instagram.com/itsthacaptain/' target='_blank' rel='noreferrer'>
                            <FaInstagramSquare className='icon-instagram' />
            </a>*/}
                    </div>
                </div>
            </Animate>

            <Modal
                closeTimeoutMS={1500}
                isOpen={isModalOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                style={{
                    overlay: {
                      backgroundColor: '#ffffff87',
                      position: 'absolute',
                      zIndex: '5',
                      width: '90%',
                      margin: 'auto',
                      color: '#000'                    },
                    content: {
                      backgroundColor: '#ffffff87',
                      border: 'none',
                      padding: '0',
                      marginTop: '40px',
                      overflow: 'hidden'
                    }
                }}
            >
                <RiCloseFill 
                    onClick={toggleModal}
                    style={{
                        position: 'absolute',
                        top: '6px',
                        right: '0px',
                        fontSize: '30px',
                        cursor: 'pointer'
                    }}
                    />
                    <object
                    width="100%"
                    height="100%"
                    data={Resume}
                    type="application/pdf"
                    style={{marginTop: '40px'}}
                >
                </object>
            </Modal>

        </section>
    )
};

export default Home;