export const navMenus = [
    {
        id: 1,
        label: 'HOME',
        path: '/',
        visible: true
    },
    {
        id: 2,
        label: 'ABOUT ME',
        path: '/about',
        visible: true,
        
    },
    {
        id: 2,
        label: 'SKILLS',
        path: '/skills',
        visible: true,
    },
    {
        id: 3,
        label: 'RESUME',
        path: '/resume',
        visible: true
    },
    {
        id: 4,
        label: 'PORTFOLIO',
        path: '/portfolio',
        visible: false
    },
    {
        id: 5,
        label: 'CONTACT',
        path: '/contact',
        visible: true
    }
]
