import './App.scss';
import Navbar from './components/navabr';
import {Routes, Route, useLocation} from 'react-router-dom';
import Particles from 'react-tsparticles';
import ParticlesConfig from './helpers/particlesConfig';
import { loadFull } from 'tsparticles';
import Home from './containers/home';
import About from './containers/about';
import Skills from './containers/skills';
import Resume from './containers/resume';
import Contact from './containers/contact';
import Portfolio from './containers/portfolio';

function App() {
  const particlesInit = async (main) => {
    await loadFull(main);
  }

  const currentPageIsHome = useLocation().pathname === '/';

  return (
    <div className="App">
        { /* particle js */ }
        { currentPageIsHome && (<Particles id='particles' options={ParticlesConfig} init={particlesInit} />) }
        
        { /* navbar component */ }
        <div className="App__navbar-wrapper">
          <Navbar />
        </div>

        { /* main content components */ }
        <div className="App__main-content-wrapper">
          <Routes>
            <Route path='/' index element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/skills' element={<Skills />} />
            <Route path='/resume' element={<Resume />} />
            <Route path = '/portfolio' element={<Portfolio />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </div>
    </div>
  );
}

export default App;
