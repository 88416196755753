import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiCloseFill, RiMenu3Line } from 'react-icons/ri';
import { CgDarkMode } from 'react-icons/cg';
//eslint-disable-next-line
import { ReactComponent as Logo } from '../../files/images/logo-icon.svg'
import { navMenus } from './config';
import './styles.scss';

const Navbar = () => {
    const [showMobileNav, setShowMobileNav] = useState(false);
    const handleShowMobileNav = () => {
        setShowMobileNav(!showMobileNav);
    }

    const closeMobileNav = () => {
        setShowMobileNav(false);
    }

    let currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';

    if (currentTheme) {
        document.documentElement.setAttribute('data-theme', currentTheme);
    
        if (currentTheme === 'light') {
            document.documentElement.setAttribute('data-theme', 'light');
        } else {
            document.documentElement.setAttribute('data-theme', 'dark');
        }
    }

    function switchTheme(e) {
        if (currentTheme === 'light') {
            document.documentElement.setAttribute('data-theme', 'dark');
            currentTheme = 'dark';
            localStorage.setItem('theme', 'dark');
        }
        else {
            document.documentElement.setAttribute('data-theme', 'light');
            currentTheme='light'
            localStorage.setItem('theme', 'light');
        }    
    }

    return (
        <div>
            <nav className='navbar'>
                <div className="navbar__container">
                    <div className="navbar__container__logo">
                    <Link className='' to={'/'}>
                    {/*<Logo className='navbar__container__logo__icon' size={30} />*/}
                    <Logo className='navbar__container__logo__icon' size={30} />
                    <span className="navbar__container__logo__name">Daniel Boggs</span>
                    </Link>
                    </div>
                    
                    <ul className={showMobileNav ? 'navbar__container__menu active' : 'navbar__container__menu' }>
                        {
                            navMenus.filter((item) => item.visible).map((item, key) => (
                                <li key={key} className='navbar__container__menu__item'>
                                    <Link className='navbar__container__menu__item__link' to={item.path} onClick={closeMobileNav}>
                                        {item.label}
                                    </Link>
                                </li>
                            ))
                        }
                        <li className='navbar__container__menu__dark-mode-toggle'>
                            <CgDarkMode className='navbar__container__menu__dark-mode-toggle__icon' onClick={()=>switchTheme()} />
                        </li>
                    </ul>
                    <div className='mode-icon' onClick={()=>switchTheme()} >
                    <CgDarkMode size={30} />
                    </div>
                    <div className="nav-icon" onClick={handleShowMobileNav}>
                        {showMobileNav ? <RiCloseFill size={30} /> : <RiMenu3Line size={30} />}
                    </div>
                </div>
            </nav>
        </div>
    )
};

export default Navbar;
